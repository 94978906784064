
    const items = [
        {
            path: '/#intro',
            text: 'Intro'
        },
        {
            path: '/#t-mobile',
            text: 'T-Mobile'
        },
        {
            path: '/#rocket',
            text: 'Rocket Communications'
        },
        {
            path: '/#amazon',
            text: 'Amazon'
        },
        {
            path: '/#freelance',
            text: 'Eastside Wedding Videography'
        },
        {
            path: '/#personal',
            text: 'Personal Projects'
        }
    ];

    export default items;
